<template>
    <div>
        <h5>请选择视频：</h5>
        <van-field v-model="selectedDir" is-link readonly label="章节" placeholder="请选择章节" @click="showDirPicker = true" />
        <van-field v-model="selectedVid" is-link readonly label="视频" placeholder="请选择视频" @click="showVidPicker = true" />
        <h5>已选中视频：</h5>
        <van-swipe-cell v-for="item in submitVideos" :key="item.id">
            <van-cell  :border="false" :title="item.name" :value="updateList(item.id)" />
            <template #right>
                <van-button square type="danger" text="删除" @click="deleteVid(item.id)"/>
            </template>
        </van-swipe-cell>
        <van-button round block type="info" native-type="submit" @click="submit" :loading="subLoading" ref="subBtn" loading-text="提交中...">提交</van-button>
        <van-popup v-model="showDirPicker" round position="bottom">
            <van-picker show-toolbar :columns="columns" @cancel="showDirPicker = false" @confirm="onConfirm" />
        </van-popup>
        <van-popup v-model="showVidPicker" round position="bottom">
            <van-checkbox-group v-model="videoResult">
                <van-cell-group>
                    <van-cell v-for="item in videoList" clickable :key="item.directoryId" :title="item.directoryName">
                        <template #right-icon>
                            <van-tag type="primary">进度：{{item.ruminantThan}}%</van-tag>
                            <van-checkbox :name="item.directoryName" :data-did = "item.directoryId" ref="checkboxes" />
                        </template>
                    </van-cell>
                    <van-button type="primary" block round color="linear-gradient(to right, #84fab0 0%, #8fd3f4 51%, #84fab0 100%)"
                    @click="selectVid"
                    >确定选中</van-button>
                </van-cell-group>
            </van-checkbox-group>
        </van-popup>
        <van-overlay :show="show" @click="show = false">
            <van-loading size="24px" class="centered-loading">加载中...</van-loading>
</van-overlay>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { Dialog } from 'vant'
export default {
    data() {
        return {
            show : false,
            submitAfter: false,
            subErr: [],
            subLoading: false,
            selectedDir: '',
            selectedVid: '',
            showDirPicker: false,
            showVidPicker: false,
            videos:[],
            videoResult:[],
            columns:[],
            videoList:[],
            submitVideos:[]
        }
    },
    methods:{
        selectVid(){
            this.selectedVid = ''
            this.$refs.checkboxes.forEach(element => {
                if(element.checked){
                    this.selectedVid += element.name + ' '
                    if(this.submitVideos.find(e => e.name == element.name)){
                        return
                    }
                    this.submitVideos.push({id:element.$el.dataset.did,name:element.name})
                }
            });
            this.showVidPicker = false
        },
        onConfirm(value){
            this.selectedDir = value
            this.videos.forEach(item => {
                if(item.directoryName == value){
                    this.videoList = item.children
                }
            })
            this.selectedVid = ''
            this.showDirPicker = false
            console.log(this.videoList)
        },
        loadData(){
            this.show = true
            if(localStorage.getItem('token') == null){
                return
            }
            let id = this.$route.params.id
        this.$http.get('/moot/getvideos/?courseId=' + id,{headers:{
            Authorization: 'Bearer ' + localStorage.getItem('token')
        }}).then(res => {
            this.show = false
            if(res.data.code == 200){
                this.videos = res.data.data
            this.videos.forEach(item => {
                this.columns.push(item.directoryName)
            })
            }else if(res.data.code == 401){
                Dialog.alert({
                title: '提示',
                message: '登录状态已过期',
            }).then(() => {
                localStorage.removeItem('token')
                this.$router.push('/')
            });
            }else{
                Dialog.alert({
                title: '提示',
                message: '未知异常',
            }).then(() => {
                
            });
            }
            
        })
        
        },
        deleteVid(id){
            this.submitVideos = this.submitVideos.filter(item => item.id != id)
        },
        updateList(id)
        {
            console.log(this.submitAfter)
            if(!this.submitAfter){
                return '未提交'

            }
            let isTrue = false
            this.subErr.forEach(item => {
                if(item == id){
                    isTrue = true
                }
            })
            if(isTrue){
                
                return '提交异常'
            }else{
                return '提交完成'
            }
            
        },
        async submit(){
            let err = []
            this.submitAfter = false
            this.subLoading = true
            let num = 0
            for(const item of this.submitVideos)
            {
                try{
                    let res = await this.$http.get('/moot/send/?directory=' + item.id,{headers:{Authorization:localStorage.getItem('token')}})
                    if(res.data.code != 0){
                        
                        num ++
                        
                        err.push(item.id)
                    }
                    console.log(res.data)
                }catch(e)
                {
                    err.push(item.id)
                }
            }
            if(num == 0){
                this.submitAfter = true
                Toast.success('提交成功');
            }else{
                this.submitAfter = true
                Toast.fail('提交异常,数量：' + num);
            }
            this.subLoading = false
            this.subErr = err
            
        }
    },
    created() {
        if(localStorage.getItem('token') == null){
            Dialog.alert({
                title: '提示',
                message: '检测到您未登录，前往登录？',
            }).then(() => {
                this.$router.push('/')
            });
        }
        this.loadData()
    }
}
</script>

<style>
    .centered-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>