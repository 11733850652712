import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeVue from '../views/Home.vue'
import SelectorVue from '../views/Selector.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeVue
  },
  {
    path:'/selector/:id',
    name:'selector',
    component:SelectorVue
  }
]

const router = new VueRouter({
  routes
})

export default router
