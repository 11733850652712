<template>
    <div>
        <van-notice-bar scrollable text="技术是开发它的人的共同灵魂。" />
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <van-card v-for="item in data" :key="item.courseId" :desc="item.recordTeache" :title="item.courseName"
                :thumb="item.courseCover" @click="courseClick(item.courseId)" />
        </van-list>
        <transition name="van-fade">
            <van-popup v-model="show" position="bottom" round :close-on-click-overlay="false">
                <van-form @submit="onSubmit">
                    <van-field v-model="username" name="用户名" label="用户名" placeholder="用户名"
                        :rules="[{ required: true, message: '请填写用户名' }]" />
                    <van-field v-model="password" type="password" name="密码" label="密码" placeholder="密码"
                        :rules="[{ required: true, message: '请填写密码' }]" />
                    <div style="margin: 16px;">
                        <van-button round block type="info" native-type="submit" ref="loginBtn">登录</van-button>
                    </div>
                </van-form>
            </van-popup>
        </transition>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { Dialog } from 'vant'
import { RouterLink } from 'vue-router';
export default {
    data() {
        return {
            username: '',
            password: '',
            show: false,
            loading: false,
            finished: false,

            data: [{ name: '测试', directory: 1 }]
        }
    },
    methods: {
        async onSubmit() {
            this.$refs.loginBtn.loading = true
            let res = await this.$http.post('/moot/loginmoot', {
                username: this.username,
                password: this.password
            })
            if (res.data.code == 200) {
                localStorage.setItem('token', res.data.data.token);
                Toast.success('登录成功')
                await this.onLoad()
                this.show = false
            } else {
                Toast.fail('登录失败')
            }
            this.$refs.loginBtn.loading = false
        },
        async onLoad() {
            if(localStorage.getItem('token') == null){
                return
            }
            this.loading = true
            let res = await this.$http.get('/moot/getCourses?limit=20&page=1', {
                params: {
                    // Add a timestamp or unique parameter to avoid caching
                    timestamp: new Date().getTime(),
                },
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
            if (res.data.code == 401) {
                Dialog.alert({
                    title: '提示',
                    message: '登录状态已过期，重新登录？',
                }).then(() => {
                    this.show = true
                });
            } else {
                this.data = res.data.data.list
                this.loading = false
                this.finished = true
            }

        },
        async courseClick(id) {
            this.$router.push(`/selector/${id}`)
        }
    },
    created() {
        if (localStorage.getItem('token') == null) {
            Dialog.alert({
                title: '提示',
                message: '检测到您未登录，前往登录？',
            }).then(() => {
                this.show = true
            });
        }
    }
}
</script>

<style></style>