<template>
  <div id="app">
    <nav>
      <van-nav-bar title="Moot" left-text="返回" right-text="按钮" left-arrow @click-left="left" border/>
    </nav>
    <router-view />
    <h5>友情链接：</h5>
    <div class="links-content">
      <div class="link-navigation">
        <div class="card">
          <img class="ava" src="https://cdn.jsdelivr.net/gh/hvnobug/assets/common/avatar.png" />
          <div class="card-header">
            <div>
              <a href="https://nxsir.cn/">我的个人主页</a>
            </div>
            <div class="info">这是一个个人主页。</div>
          </div>
        </div>
        <div class="card">
          <img class="ava" src="https://cdn.jsdelivr.net/gh/hvnobug/assets/common/avatar.png" />
          <div class="card-header">
            <div>
              <a href="https://blog.nxsir.cn/">NX’s blog</a>
            </div>
            <div class="info">这是一个分享IT技术的小站。</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div></template>

<script>
export default {
  methods: {
    left() {
      this.$router.back()
    }
  }
}
</script>

<style>
:root {
  --van-nav-bar-background-color: #000000;
  --van-nav-bar-title-color: #ffffff;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.links-content {
  margin-top: 1rem;
}

.link-navigation{
  display: flex;
  flex-wrap: wrap;
}

.link-navigation::after {
  content: " ";
  display: block;
  clear: both;
}

.card {
  width: 80%;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 4px;
  transition-duration: 0.15s;
  margin-bottom: 1rem;
  display: flex;
}

.card:nth-child(odd) {
  float: left;
}

.card:nth-child(even) {
  float: right;
}

.card:hover {
  transform: scale(1.1);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
}

.card a {
  border: none;
}

.card .ava {
  width: 3rem !important;
  height: 3rem !important;
  margin: 0 !important;
  margin-right: 1em !important;
  border-radius: 4px;
}

.card .card-header {
  font-style: italic;
  overflow: hidden;
  width: 100%;
}

.card .card-header a {
  font-style: normal;
  color: #2bbc8a;
  font-weight: bold;
  text-decoration: none;
}

.card .card-header a:hover {
  color: #d480aa;
  text-decoration: none;
}

.card .card-header .info {
  font-style: normal;
  color: #a3a3a3;
  font-size: 14px;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
}</style>
